import axios from 'axios';

const instance = axios.create({
  headers: { Pragma: 'no-cache' },
  // Local
  // baseURL: 'https://api-taqa.mmandb-dev.co.uk',
  // Staging
  // baseURL: 'https://dkb-vle-api.mmandb-staging.co.uk',
  // Production
  baseURL: 'https://api.taqa-dkb-training.co.uk',
});

export default instance;
