import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueAnalytics from 'vue-analytics';
import JsonCSV from 'vue-json-csv';
import VueEditor from 'vue2-editor';
import VueMoment from 'vue-moment';
import { format } from 'date-fns';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.component('downloadCsv', JsonCSV);

Vue.use(VueCookies);
Vue.use(VueEditor);
Vue.use(VueMoment);
Vue.use(VueAnalytics, {
  id: 'UA-123456789-1',
  router,
  disabled: true,
});

Vue.filter('tinyDate', (value) => {
  if (!value) return '';
  const prettyDate = format(new Date(value), 'do MMM yyy');
  return prettyDate;
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
