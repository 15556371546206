<template>
  <div class="footer">
    <div class="grid-x grid-padding-x pad-bottom">
      <div class="cell small-12">
        <span class="footer-border"></span>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2">
        <img src="../../assets/images/dkb_logo_large.jpg" alt="DKB Training" />
      </div>
      <div class="cell small-12 medium-10">
        <p class="text-purple text-right">
           01924 842027<br />
           <a
            class="text-purple"
            href="mailto:info@dkb-training.co.uk">
             info@dkb-training.co.uk
            </a><br />
            © DKB Training 2021
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    authData() {
      return this.$store.getters.authData;
    },
    currentUserName() {
      return this.$store.getters.currentUserName;
    },
  },
};
</script>
