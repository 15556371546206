import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store/index';
import Index from '@/views/Index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/log-out',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "login" */ '../views/Logout.vue'),
  },
  {
    path: '/request-password',
    name: 'RequestPassword',
    component: () => import(/* webpackChunkName: "requestpassword" */ '../views/RequestPassword.vue'),
  },
  {
    path: '/reset-password/:hash/:id',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetpassword" */ '../views/ResetPassword.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/add-file',
    name: 'AddFile',
    component: () => import(/* webpackChunkName: "addFile" */ '../views/AddFile.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/admin-add-file/:id',
    name: 'AdminAddFile',
    component: () => import(/* webpackChunkName: "addFile" */ '../views/AdminAddFile.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/add-ws',
    name: 'AddWorkSheet',
    component: () => import(/* webpackChunkName: "addWorkSheet" */ '../views/AddWorkSheet.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/admin-add-ws/:id',
    name: 'AdminAddWorkSheet',
    component: () => import(/* webpackChunkName: "addWorkSheet" */ '../views/AdminAddWorkSheet.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/view-file/:id',
    name: 'ViewFile',
    component: () => import(/* webpackChunkName: "viewFile" */ '../views/ViewFile.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/view-matrix/:id',
    name: 'ViewMatrix',
    component: () => import(/* webpackChunkName: "viewMatrix" */ '../views/ViewMatrix.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/view-certificate/:id',
    name: 'ViewCertificate',
    component: () => import(/* webpackChunkName: "viewCertificate" */ '../views/ViewCertificate.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/messages/:id',
    name: 'Messages',
    component: () => import(/* webpackChunkName: "Messages" */ '../views/Messages.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/send-message/:id?',
    name: 'SendMessage',
    component: () => import(/* webpackChunkName: "sendMessge" */ '../views/SendMessage.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/view-message/:id',
    name: 'ViewMessage',
    component: () => import(/* webpackChunkName: "viewMessge" */ '../views/ViewMessage.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/view-record-of-acheivement/:id',
    name: 'ViewRecordOfAcheivement',
    component: () => import(/* webpackChunkName: "viewRecordOfAcheivement" */ '../views/ViewRecordOfAcheivement.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/view-observation-request/:id',
    name: 'ViewObservationRequest',
    component: () => import(/* webpackChunkName: "viewObservationRequest" */ '../views/ViewObservation.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: () => import(/* webpackChunkName: "editProfile" */ '../views/EditProfile.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/view-worksheet/:id',
    name: 'ViewWorksheet',
    component: () => import(/* webpackChunkName: "viewWorksheet" */ '../views/ViewWorksheet.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/list-users',
    name: 'ListUsers',
    component: () => import(/* webpackChunkName: "listUsers" */ '../views/ListUsers.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/learners-az',
    name: 'LearnersAZ',
    component: () => import(/* webpackChunkName: "learnersAZ" */ '../views/AzLearners.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/learners-archive',
    name: 'ArchiveLearners',
    component: () => import(/* webpackChunkName: "archiveLearners" */ '../views/ArchiveLearners.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/add-user',
    name: 'AddUser',
    component: () => import(/* webpackChunkName: "addUser" */ '../views/AddUser.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/add-matrix/:id',
    name: 'AddMatrix',
    component: () => import(/* webpackChunkName: "addMatrix" */ '../views/AddMatrix.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/add-certificate/:id',
    name: 'AddCertificate',
    component: () => import(/* webpackChunkName: "addCertificate" */ '../views/AddCertificate.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/add-record-of-acheivement/:id',
    name: 'AddRecordOfAcheivement',
    component: () => import(/* webpackChunkName: "addRecordOfAcheivement" */ '../views/AddRecordOfAcheivement.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/view-user/:id',
    name: 'ViewUser',
    component: () => import(/* webpackChunkName: "viewUser" */ '../views/ViewUser.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/edit-user/:id',
    name: 'EditUser',
    component: () => import(/* webpackChunkName: "editUser" */ '../views/EditUser.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/add-tutor-group',
    name: 'AddTutorGroup',
    component: () => import(/* webpackChunkName: "addTutorGroup" */ '../views/AddTutorGroup.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/book-observation/:id/:obsNo',
    name: 'BookObservation',
    component: () => import(/* webpackChunkName: "bookObservation" */ '../views/BookObservation.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/resources/',
    name: 'Resources',
    component: () => import(/* webpackChunkName: "resources" */ '../views/Resources.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/view-tutor-groups/',
    name: 'ViewTutorGroups',
    component: () => import(/* webpackChunkName: "viewTutorGroups" */ '../views/ViewTutorGroups.vue'),
    beforeEnter(to, from, next) {
      if (!Store.state.idToken) {
        Store.dispatch('checkStoredToken');
      }
      if (Store.state.idToken) {
        next();
      } else {
        next('/login');
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
