<template>
  <div class="header">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <img src="../../assets/images/dkb_logo_large.jpg" alt="DKB Training" />
      </div>
      <div class="cell small-12 medium-3">
        <p class="text-purple">
           01924 842027<br />
           <a
            class="text-purple"
            href="mailto:info@dkb-training.co.uk">
             info@dkb-training.co.uk
            </a>
        </p>
      </div>
      <div class="cell small-12 medium-3">
        <span class="course-indicator">Course: {{ courseChosen | chosenCourseFilter }}</span>
        &nbsp;|&nbsp;
        <span v-on:click="changeCourse = true" class="link" v-if="courseNumber === '2'">
          Change Course
        </span>
      </div>
      <div class="cell small-12 medium-3 text-right">
        <ul v-if="isLoggedIn === true">
          <router-link class="button marg-right" tag="li" to="/help">Help</router-link>
          <router-link class="button" tag="li" to="/log-out">Log out</router-link>
        </ul>
        <ul v-else>
          <router-link class="button" tag="li" to="/">Log In</router-link>
        </ul>
      </div>
    </div>
    <div
      v-if="isLoggedIn === true"
      class="grid-x grid-padding-x">
      <div class="cell small-12">
        <ul class="nav">
          <router-link
            to="/dashboard"
            tag="li">
            Dashboard
          </router-link>
          <router-link
            to="/edit-profile"
            tag="li">
            Edit profile
          </router-link>
          <router-link
            v-if="userLevel === 3 || userLevel === '3'"
            to="/add-file"
            tag="li">
            Add evidence
          </router-link>
          <router-link
            v-if="userLevel === 3 || userLevel === '3'"
            to="/add-ws"
            tag="li">
            Add coursework
          </router-link>
          <router-link
            v-if="userLevel === 1 || userLevel === '1'"
            to="/add-user"
            tag="li">
            Add user
          </router-link>
          <router-link
            v-if="userLevel === 1 || userLevel === '1'"
            to="/view-tutor-groups"
            tag="li">
            Tutor Groups
          </router-link>
          <router-link
            v-if="userLevel === 1 || userLevel === '1'"
            to="/add-tutor-group"
            tag="li">
            Add tutor group
          </router-link>
          <router-link
            v-if="userLevel === 1 || userLevel === '1'"
            to="/list-users"
            tag="li">
            View all users
          </router-link>
          <router-link
            to="/resources"
            tag="li">
            Resources
          </router-link>
        </ul>
      </div>
    </div>
    <div class="modal-wrap" v-if="changeCourse === true">
      <div class="modal-inner">
        <p>Which course would you like to access?</p>
        <select v-on:change="selectCourse" v-model="selectedCourse">
          <option value="0">Please choose:</option>
          <option value="iqa">IQA Course</option>
          <option value="ass">Assessing Certificate</option>
          <option value="aa">Assessing Award</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      changeCourse: false,
      selectedCourse: 0,
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    currentUserName() {
      return this.$store.getters.currentUserName;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    userLevel() {
      return this.$store.getters.userLevel;
    },
    courseChosen() {
      return this.$store.getters.courseChosen;
    },
    courseNumber() {
      return this.$store.getters.courseNumber;
    },
  },
  methods: {
    selectCourse() {
      this.$store.dispatch('selectCourse', this.selectedCourse);
      this.changeCourse = false;
    },
  },
  filters: {
    chosenCourseFilter(value) {
      let course = '';
      if (value === 'iqa') {
        course = 'IQA';
      }
      if (value === 'ass') {
        course = 'Assessing Certificate';
      }
      if (value === 'aa') {
        course = 'Assessing Award';
      }
      return course;
    },
  },
};
</script>
