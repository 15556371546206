<template>
  <div>
    <app-foundation></app-foundation>
    <app-typography></app-typography>
    <app-margins></app-margins>
    <app-padding></app-padding>
    <app-buttons></app-buttons>
    <app-layout></app-layout>
    <app-uploader></app-uploader>
</div>
</template>

<script>
import Foundation from '@/components/ztyles/Foundation.vue';
import Typography from '@/components/ztyles/Typography.vue';
import Margins from '@/components/ztyles/Margins.vue';
import Padding from '@/components/ztyles/Paddings.vue';
import Buttons from '@/components/ztyles/Buttons.vue';
import Layouts from '@/components/ztyles/Layouts.vue';
import Uploader from '@/components/ztyles/Uploader.vue';

export default {
  components: {
    appFoundation: Foundation,
    appTypography: Typography,
    appMargins: Margins,
    appPadding: Padding,
    appButtons: Buttons,
    appLayout: Layouts,
    appUploader: Uploader,
  },
  name: 'styles',
};
</script>
